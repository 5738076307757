import React from 'react'
import '../../sass/main/main.scss';
import ReactJs from '../../assets/technologies/react.png'
import Angular from '../../assets/technologies/angular.png'
import Vue from '../../assets/technologies/Vuejs.png'
import Ts from '../../assets/technologies/Typescript.png'
import Node from '../../assets/technologies/Nodejs.png'
import Mongodb from '../../assets/technologies/Mongodb.png'
import Mysql from '../../assets/technologies/Mysql.png'
import Graphql from '../../assets/technologies/graphql.png'
import Socket from '../../assets/technologies/socket.png'
import Git from '../../assets/technologies/Git.png'
import Docker from '../../assets/technologies/Docker.png'
import Aws from '../../assets/technologies/aws.png'

const Technologies : React.FC = () => {
    return (
        <React.Fragment>

            <section className="section-work-with">
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        We Love To Work with...
                    </h2>
                </div>
                <div className="row ml-auto mr-auto techologiesIcons">
                    <div>
                        <img src={ReactJs} alt={'ReactJS'} />
                    </div>

                    <div>
                        <img src={Angular} alt={'Angular'} />
                    </div>

                    <div>
                        <img src={Vue} alt={'Vue'} />
                    </div>

                    <div>
                        <img src={Ts} alt={'Ts'} />
                    </div>

                    <div>
                        <img src={Mongodb} alt={'Mongodb'} />
                    </div>

                    <div>
                        <img src={Node} alt={'NodeJS'} />
                    </div>

                    <div>
                        <img src={Mysql} alt={'Mysql'} />
                    </div>

                    <div>
                        <img src={Graphql} alt={'Graphql'} />
                    </div>

                    <div>
                        <img src={Socket} alt={'Socket'} />
                    </div>

                    <div>
                        <img src={Git} alt={'Git'} />
                    </div>

                    <div>
                        <img src={Aws} alt={'Aws'} />
                    </div>

                    <div>
                        <img src={Docker} alt={'Docker'} />
                    </div>
                </div>

            </section>

        </React.Fragment>
    )
}

export default Technologies
