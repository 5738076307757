import React from 'react'
import '../../sass/main/main.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faHeadset } from '@fortawesome/free-solid-svg-icons'

const ContactUs : React.FC = () => {
    return (
        <React.Fragment>
            <section className="section-contactUs">

                <div className="u-center-text">
                    <h2 className="heading-secondary">
                        <div className="u-center-text u-margin-bottom-big">
                            <h2 className="heading-secondary text-white">
                                Contact Us
                    </h2>
                        </div>
                    </h2>
                </div>


                <div className="about-body ml-auto mr-auto">
                    <div className="row contactUs">
                        <div className="col-1-of-2 card-center contactUsCard contactUsCardBorder">
                            <div className="about-card">
                                <div className="card-header">
                                    <FontAwesomeIcon icon="map-marker-alt" />
                                    <h3>Office Address</h3>
                                </div>
                            <div>
                                <div className="card-body">
                                    <p><b>504, Luxuria Trade Hub</b>&nbsp;Opp. VR mall, Dumas Rd, Surat, Gujarat 395007.</p>
                                </div>
                                <div className="card-body">
                                    <p><b>A901-A905, Vivanta Icon</b>&nbsp;Opp. Shell Petrol Pump, Adajan, Surat, Gujarat 395009.</p>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="col-1-of-2 card-center contactUsCard">
                            <div className="about-card">
                                <div className="card-header">
                                    <FontAwesomeIcon icon={faHeadset as IconProp} />
                                    <h3>General Contacts</h3>
                                </div>
                                <div className="card-body">
                                    <p>contact@loopnix.com</p>
                                    <p>+916359113632</p>
                                    <div className="display-brands">
                                        <button><FontAwesomeIcon icon={{ iconName: "facebook-f", prefix: "fab" }} /></button>
                                        <button style={{ background: "#00acee" }}><FontAwesomeIcon icon={{ iconName: "twitter", prefix: "fab" }} /></button>
                                        <a href='https://in.linkedin.com/company/loopnix'><button style={{ background: "#2473A2" }}> <FontAwesomeIcon  icon={{ iconName: "linkedin-in", prefix: "fab" }} /></button></a>
                                        <button style={{ background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)" }}><FontAwesomeIcon icon={{ iconName: "instagram", prefix: "fab" }} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </React.Fragment>
    )
}

export default ContactUs
