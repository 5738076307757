import React from 'react'

const Footer: React.FC = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="row">
                    <div className="col-1-of-4 u-center-text">
                        <p className="footer__copyright">
                            &copy; Loopnix - {new Date().getFullYear()}
                        </p>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer
