import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../sass/main/main.scss';

const Service : React.FC = () => {
    return (
        <React.Fragment>
            <section className="section-features">

                <div className="u-center-text">
                    <h2 className="heading-secondary">
                        <div className="u-center-text u-margin-bottom-big">
                            <h2 className="heading-secondary text-white">
                                Our Services
                    </h2>
                        </div>
                    </h2>
                </div>
                <div className="row ml-auto mr-auto ourServices">
                    <div>
                        <div className="feature-box mainBox">
                            <FontAwesomeIcon icon="laptop-code" />
                            <h3 className="heading-tertiary u-margin-bottom-small">Web Development</h3>
                            <p className="feature-box__text">
                                We are working with the latest frontEnd framework for web development
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="feature-box mainBox">
                            <FontAwesomeIcon icon="mobile-alt" />
                            <h3 className="heading-tertiary u-margin-bottom-small">Mobile Application Development</h3>
                            <p className="feature-box__text">
                                We are working with the latest Mobile Hybrid framework for Mobile application development.
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="feature-box mainBox">
                            <FontAwesomeIcon icon="desktop" />
                            <h3 className="heading-tertiary u-margin-bottom-small">Web Security</h3>
                            <p className="feature-box__text">
                                We are providing security for web application so attacker can't access your confidential information. 
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="feature-box mainBox">
                            <FontAwesomeIcon icon="code-branch" />
                            <h3 className="heading-tertiary u-margin-bottom-small">ML & AI</h3>
                            <p className="feature-box__text">
                                We are working with a machine learning-based solution and technology.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service
