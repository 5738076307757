import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './lib/Fontawesome'
import { routes } from "./routes";

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
          <main>
          <Routes>
          {routes.map((route, index) => (
            <Route
              key={index} 
              path={route.url}
              element={<route.element/>}
            />
          ))}
          </Routes>
          </main>
      </BrowserRouter>
    </>
  );
};

export default App;
