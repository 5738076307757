import React, {Component} from "react";
import About from "../../components/Home/About";
import Service from "../../components/Home/Service";
import Header from "../../components/Layouts/Header";
import Footer from "../../components/Layouts/Footer";
import Technologies from "../../components/Home/Technologies";
import ContactUs from "../../components/Home/ContactUs";

class Home extends Component {
  render() {
    return (
    <React.Fragment>
        <Header />
        <About />
        <Service />
        <Technologies/>
        
       
        <ContactUs/>

        <Footer />

    </React.Fragment>
    );
  }
}

export default Home;
