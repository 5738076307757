import {faLaptopCode} from '@fortawesome/free-solid-svg-icons/faLaptopCode'
import { faMobileAlt} from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import { faDesktop} from '@fortawesome/free-solid-svg-icons/faDesktop'
import { faCodeBranch} from '@fortawesome/free-solid-svg-icons/faCodeBranch'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram'
import { library } from '@fortawesome/fontawesome-svg-core'


library.add(
    faFacebookF as any,
    faTwitter as any,
    faLinkedinIn as any,
    faInstagram as any,
    
    faLaptopCode as any, 
    faMobileAlt as any, 
    faDesktop as any, 
    faCodeBranch as any, 
    faMapMarkerAlt as any,
    )