import React from 'react'
import logo from '../../assets/img/logo-white.png'
import '../../sass/main/main.scss';


const Header = () => {
  return (
    <React.Fragment>
      <header className="header">
        <div className="header__logo-box">
          <img src={logo} alt="Logo" className="header__logo" />
        </div>
          <div className="header__text-box">
            <h1 className="heading-primary">
              <span className="heading-primary--main">Loopnix</span>
              <span className="heading-primary--sub">where the future is build.</span>
            </h1>
          </div>
        </header>
    </React.Fragment>
  )
}

export default Header
