import { routesURLS } from "./routesURL";
import { IRoutes } from "./interface/routes";
import Home from "../views/Home/Home";

export const routes : Array<IRoutes> = [
  {
    name: "home",
    url: routesURLS.HOME,
    element: Home
  },
];
