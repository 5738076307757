import React from 'react'
import '../../sass/main/main.scss';

const About: React.FC = () => {
    return (
        <React.Fragment>
            <section className="section-about row ml-auto mr-auto">
                <div className="u-center-text">
                    <h2 className="heading-secondary">
                        About Us
                    </h2>
                    <p className="paragraph">
                        We are a rapidly growing JavaScript & TypeScript based company.
                        We are experts in making a single page applications.
                        We are working on Javascript based latest frameworks like Angular, React, Vue, etc.
                        We Have expertise in JavaScript & TypeScript.
                        We have expertise in NodeJS with MySQL & MongoDB.
                        Our motto is to make an application with the latest modern UI with the latest technologies.
                        We approach agile to develop an application where we plan our sprint and execute each task one by one.
                        We have passionate developers who develop a world-class applications with high standard coding.
                        We will give you the quick and best solution for your business application.
                        We always think in a creative way for application development.
                        We build an application with DevOps CI & CD to maintain perfection on server and development.
                        We follow a high design standard which matches modern UI.
                        We have been the most sought-after web development company offering notable services, like the website, Web applications, Mobile applications.
                    </p>
                </div>
            </section>
        </React.Fragment>
    )
}

export default About
